import React, { useState, useEffect, useRef } from "react";
import "./ProcessedCandidateByJob.css";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCandidateData } from "../../data/candidatedata";
import MarkFavorite from "../../components/MarkFavorite/MarkFavorite";
import { toast, ToastContainer } from "react-toastify";
import { Drawer, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { storage } from "../../libs/localStorage";
import { Helmet } from "react-helmet";
import { useJobsData } from "../../data/jobsdata";
import { commonData } from "../../data/commondata";
import ScreeningMessagePopup from "../../components/Screening/ScreeningMessagePopup";
import CandidateFilter from "../../components/CandidateFilter/CandidateFilter";
import { List } from "reactstrap";

const SortData = [
  {
    id: "latest",
    name: "Newest First",
    order: "DESC",
  },
  {
    id: "favorite",
    name: "Favorites First",
    order: "DESC",
  },
];
const capitalizeWords = (str) => {
  return str?.replace(/\b\w/g, (char) => char?.toUpperCase());
};

function ProcessedCandidateByJob() {
  const [toggleView, setToggleView] = useState(false);
  const [detailsView, setDetailsView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const { GetCustomerById } = useCandidateData();
  const params = location.state;
  const { retrieveToken } = storage();
  const [data, setData] = useState({});
  const {
    GetProcessedCandidateByJob,
    MarkAnalysisFavorite,
    GetCvStatus,
    UpdateCvStatus,
    AddScreeningNotes,
  } = useCandidateData();
  const { GetDownloadUrl } = commonData();
  const navigate = useNavigate();
  const activetab = "";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const token = retrieveToken();
  const [isSecondOpen, setSecondIsOpen] = useState(false);
  const [isthirdOpen, setthirdIsOpen] = useState(false);
  const [CvStatusfilterdata, setCvStatusFilterdata] = useState("Status");
  const [filterdata, setFilterdata] = useState("Sort By");
  const [customer, setcustomer] = useState(null);
  const [CvStatus, setCvStatus] = useState(null);
  const [screeningNote, setScreeningNote] = useState("");
  const [analysisid, setAnalysisid] = useState(null);
  const [CvStatusMessage, setCvStatusMessage] = useState();
  const userType = localStorage.getItem("usertype");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [oldNote, setOldNote] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const thirdDropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);
  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const [notificationActive, setNotificationActive] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(["Arab"]);
  const [ethnicity, setEthnicity] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [gender, setGender] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  // const [filterList, setFilterList] = useState("");
  const [openPhoneDrawer, setOpenPhoneDrawer] = useState(false);
  const [openSecondPhoneDrawer, setOpenSecondPhoneDrawer] = useState(false);
  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [isUpdateGender, setIsUpdateGender] = useState(false);
  const [filters, setFilters] = useState({
    jobid: params?.jobid,
    search: "",
    cvstatus: "All",
    sortby: "percentage",
    sortorder: "DESC",
    selectedfilter: "",
    filterlist: "",
  });

  // Function to update filters state
  const updateFilter = (newFilter) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilter,
    }));
  };

  const handleClick = () => {
    setNotificationActive(!notificationActive);
  };
  const { GetAtsUrlByAtsJobcode, MarkJobFavorite } = useJobsData();

  const toggleSecondDropdown = () => {
    setSecondIsOpen((prev) => !prev);
  };

  const handleSecondOptionClick = (Secondoption) => {
    updateFilter({
      sortby: Secondoption.id,
      sortorder: "ASC",
    });
    // setSelectedFilterdata(Secondoption.id);
    setFilterdata(Secondoption.name);
    // setSortOrder(Secondoption.order);
    toggleSecondDropdown();
  };

  const toggleThirdDropdown = () => {
    setthirdIsOpen((prev) => !prev);
  };

  const handleThirdOptionClick = (data) => {
    // setSelectedCvStatusFilterdata(data.cvstatusname);
    setCvStatusFilterdata(data.cvstatusname);
    updateFilter({
      cvstatus: data.cvstatusname,
    });

    toggleThirdDropdown();
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   switch (selectedFilter) {
  //     case "DE&I":
  //       setEthnicity(ethnicity?.map((item) => ({ ...item, isChecked: false })));
  //       break;
  //     case "NATIONALITY":
  //       setNationality(nationality?.map((item) => ({ ...item, isChecked: false })));
  //       break;
  //     default:
  //       break;
  //   }
  // }, [selectedFilter]);

  const getCounts = (data, key) => {
    if (!data || !Array.isArray(data)) {
      // If data is null, undefined, or not an array, return an empty array
      // console.error("Invalid input data.");
      return [];
    }
    const counts = data?.reduce((acc, item) => {
      const itemKey = item?.[key];
      if (itemKey !== undefined && itemKey !== null) {
        acc[itemKey] = (acc[itemKey] || 0) + 1;
      }
      return acc;
    }, {});

    // const filteredCounts = Object.fromEntries(
    //   Object.entries(counts).filter(([key, value]) => value !== 0 && value !== '')
    // );

    if (Object.keys(counts).length === 0) {
      return [];
    }

    const toTitleCase = (str) => {
      return str.toLowerCase().replace(/\b(\w)/g, (char) => char.toUpperCase());
    };

    const result = Object?.entries(counts)?.map(([name, count]) => ({
      id: name?.trim(),
      name: toTitleCase(name),
      count: count,
      isChecked: false,
    }));

    const sortedResult = result.sort((a, b) => a.name.localeCompare(b.name));
    return sortedResult.filter((item) => item?.id && item?.id !== "unknown");
  };

  const getCurrLoc = (data, key) => {
    if (!data || !Array.isArray(data)) {
      // If data is null, undefined, or not an array, return an empty array
      // console.error("Invalid input data.");
      return [];
    }
    const names = data?.flatMap((item) =>
      item?.[key]?.split(",")?.map((name) => name?.trim())
    );
    const nameCount = names?.reduce((countMap, name) => {
      countMap[name] = (countMap[name] || 0) + 1;
      return countMap;
    }, {});

    if (Object.keys(nameCount).length === 0) {
      return [];
    }

    const toTitleCase = (str) => {
      return str.toLowerCase().replace(/\b(\w)/g, (char) => char.toUpperCase());
    };

    const result = Object?.entries(nameCount)?.map(([name, count]) => ({
      id: name?.trim(),
      name: toTitleCase(name),
      count: count,
      isChecked: false,
    }));

    const sortedResult = result.sort((a, b) => a.name.localeCompare(b.name));
    return sortedResult.filter((item) => item?.id && item?.id !== "unknown");
  };

  const downloadResume = (url) => {
    url &&
      GetDownloadUrl({
        url: url,
      })
        .then((response) => {
          window.open(response?.data?.url, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
  };

  useEffect(() => {
    if (gender && isUpdateGender) {
      const updateGender = gender?.map((person) => ({
        ...person,
        name:
          person?.name === "F"
            ? "Female"
            : person?.name === "M"
            ? "Male"
            : person?.name === "O"
            ? "Other"
            : person?.name,
      }));
      setGender(updateGender);
      setIsUpdateGender(false);
    }
  }, [gender, isUpdateGender]);

  useEffect(() => {
    if (token) {
      GetCustomerById()
        .then((response) => {
          setcustomer(response?.data[0]);
          response?.data[0]?.isdetailview === true && setDetailsView(true);
        })
        .catch((error) => {
          console.error(error);
        });
      GetProcessedCandidateByJob(filters)
        .then((response) => {
          if (response?.data == (undefined || null)) {
            setData([]);
            setNoDataAvailable(true);
          } else {
            setData(
              response?.data?.map((x) => ({
                ...x,
                candidatesdata: JSON.parse(x?.candidatesdata),
              }))
            );
            setNoDataAvailable(false);
            setEthnicity(
              getCounts(
                JSON.parse(response?.data[0]?.candidatesdata),
                "ethnicity"
              )
            );
            setNationality(
              getCounts(
                JSON.parse(response?.data[0]?.candidatesdata),
                "nationality"
              )
            );
            setCurrentLocation(
              getCurrLoc(
                JSON.parse(response?.data[0]?.candidatesdata),
                "currentjoblocation"
              )
            );
            setGender(
              getCounts(JSON.parse(response?.data[0]?.candidatesdata), "gender")
            );
            setIsApplyFilter(true);
            setIsUpdateGender(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      GetCvStatus()
        .then((response) => {
          setCvStatus(response?.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  useEffect(() => {
    if (isApplyFilter) {
      GetProcessedCandidateByJob(filters)
        .then((response) => {
          if (response?.data == (undefined || null)) {
            setData([]);
            setNoDataAvailable(true);
          } else {
            setData(
              response?.data?.map((x) => ({
                ...x,
                candidatesdata: JSON.parse(x?.candidatesdata),
              }))
            );
            setNoDataAvailable(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [filters]);

  const btnMarkFavorite = (cand) => {
    MarkAnalysisFavorite({
      analysisid: cand?.analysisid,
      isfavorite: !cand?.isfavorite,
    })
      .then((response) => {
        const itemIndex = data[0]?.candidatesdata.findIndex(
          (item) => item.analysisid === cand?.analysisid
        );

        if (itemIndex !== -1) {
          const updatedCandidatesData = [...data[0]?.candidatesdata];
          updatedCandidatesData[itemIndex] = {
            ...updatedCandidatesData[itemIndex],
            isfavorite: !cand?.isfavorite,
          };
          setData([{ ...data[0], candidatesdata: updatedCandidatesData }]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const MarkJobFavoriteApi = (job) => {
    MarkJobFavorite({
      jobid: job?.jobid,
      isfavorite: !job?.isfavorite,
    })
      .then((response) => {
        const itemIndex = data?.findIndex((item) => item?.jobid === job?.jobid);
        if (itemIndex !== -1) {
          const updatedJobData = [...data];
          updatedJobData[itemIndex] = {
            ...updatedJobData[itemIndex],
            isfavorite: !job?.isfavorite,
          };
          setData(updatedJobData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const buttonHandle = () => {
    setToggleView(false);
    setDetailsView(!detailsView);
  };
  const buttonHandle2 = () => {
    // setToggleView(!toggleView);
    setDetailsView(false);
  };

  const clearSearch = () => {
    setSearchText("");
    if (filters?.search) {
      updateFilter({
        search: "",
        cvstatus: "All",
        sortby: "percentage",
        sortorder: "DESC",
        selectedfilter: "",
        filterlist: "",
      });
      setFilterdata("Sort By");
      setCvStatusFilterdata("Status");
      setSelectedFilter("");
    }
  };

  const btnSearch = () => {
    updateFilter({
      search: searchText,
      cvstatus: "All",
      sortby: "percentage",
      sortorder: "DESC",
      selectedfilter: "",
      filterlist: "",
    });
    setSelectedFilter("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setSecondIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        thirdDropdownRef.current &&
        !thirdDropdownRef.current.contains(event.target)
      ) {
        setthirdIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // For Date and time function
  function formatDateTime(dateString) {
    const date = new Date(dateString);

    // Format the date as MM/DD/YY
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });

    // Format the time as HH:MMam/pm
    let formattedTime = date
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(" ", " ")
      .toUpperCase(); // Remove the space and convert to lowercase

    return `${formattedDate} ${formattedTime}`;
  }
  function formatDateTime2(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });

    return `${formattedDate} `;
  }
  function getOpenDayLabel(openDays) {
    const dayLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date();
    const openDate = new Date(today);
    openDate.setDate(today.getDate() - openDays);

    const daysAgo = Math.floor((today - openDate) / (1000 * 60 * 60 * 24));
    const openDayOfWeek = openDate.getDay();

    if (daysAgo === 0) {
      return "Opened Today";
    } else if (daysAgo === 1) {
      return "Opened Yesterday";
    } else if (daysAgo < 7) {
      return `Opened ${dayLabels[openDayOfWeek]}`;
    } else {
      return `Opened on\n${openDate
        .toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-")}`;
    }
  }
  const yourColumnsArray = [
    { id: 1, title: "Column 1", isVisible: true },
    { id: 2, title: "Column 2", isVisible: true },
    { id: 3, title: "Column 7", isVisible: true },
    { id: 4, title: "Column 3", isVisible: customer?.show_deni },
    { id: 5, title: "Column 4", isVisible: customer?.ismiddleeast },
    { id: 6, title: "Column 5", isVisible: customer?.isgender },
    { id: 7, title: "Column 6", isVisible: customer?.iscurrentjoblocation },
    { id: 8, title: "Column 8", isVisible: true },
    { id: 9, title: "Column 9", isVisible: true },
    { id: 10, title: "Column 10", isVisible: true },
    { id: 11, title: "Column 11", isVisible: true },
    { id: 12, title: "Column 12", isVisible: customer?.isexperiencesimiliar },
    { id: 13, title: "Column 13", isVisible: customer?.iscustomcriticalskill },
    { id: 14, title: "Column 14", isVisible: customer?.ishighestqualification },
    { id: 15, title: "Column 15", isVisible: customer?.isdrivinglic },
    { id: 16, title: "Column 16", isVisible: customer?.isarabicspeaking },
    { id: 17, title: "Column 17", isVisible: customer?.isoverqualified },
    { id: 18, title: "Column 18", isVisible: customer?.isoverexperienced },
    { id: 19, title: "Column 19", isVisible: customer?.isage },
    { id: 20, title: "Column 20", isVisible: true },
    { id: 21, title: "Column 21", isVisible: true },
  ];

  const totalColumns = 21;
  const fullWidth = 3400; // Maximum width when all columns are visible
  const columnWidth = fullWidth / totalColumns;

  const visibleColumns = yourColumnsArray.filter(
    (column) => column.isVisible
  ).length;
  const tableWidth = Math.round(visibleColumns * columnWidth);

  const handleStatusChange = (id, newStatus) => {
    UpdateCvStatus({
      analysisid: id,
      status: newStatus,
    })
      .then((response) => {
        setCvStatusMessage(response?.header?.message);

        if (response?.header?.status === 200) {
          const updatedCandidatesData = [...data[0]?.candidatesdata].map(
            (item) =>
              item.analysisid === id ? { ...item, cvstatus: newStatus } : item
          );

          setData([{ ...data[0], candidatesdata: updatedCandidatesData }]);

          if (response?.header?.message === "CV status updated successfully") {
            toast.success(
              <span
                style={{
                  font: "rubik regular",
                  color: "black",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                {response?.header?.message}
              </span>
            );
          } else {
            toast.error(
              <span
                style={{
                  font: "rubik regular",
                  color: "black",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                {response?.header?.message}
              </span>
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error updating CV status:", error);
      });
  };

  const handleItemClick = (item) => {
    setAnalysisid(item?.analysisid);
    setScreeningNote(item?.screeningnote);
    setOldNote(item?.screeningnote);
    setIsPopupOpen(true);
    setIsSave(item?.screeningnote ? false : true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setAnalysisid(null);
    setScreeningNote("");
    setErrorMessage("");
  };

  const handleSaveChanges = () => {
    if (analysisid !== null) {
      // if (screeningNote?.trim()) {
      AddScreeningNotes({
        screeningnote: screeningNote?.trim(),
        analysisid: analysisid,
      })
        .then((response) => {
          response?.header?.status == 200
            ? toast.success(
                <span
                  style={{
                    font: "rubik regular",
                    color: "black",
                    fontWeight: "normal",
                    fontSize: "15px",
                  }}
                >
                  Screening note updated
                </span>
              )
            : toast.error(
                <span
                  style={{
                    font: "rubik regular",
                    color: "black",
                    fontWeight: "normal",
                    fontSize: "15px",
                  }}
                >
                  {response?.header?.message}
                </span>
              );
          const itemIndex = data[0]?.candidatesdata?.findIndex(
            (item) => item?.analysisid === analysisid
          );

          if (itemIndex !== -1) {
            const updatedCandidatesData = [...data[0]?.candidatesdata];
            updatedCandidatesData[itemIndex] = {
              ...updatedCandidatesData[itemIndex],
              screeningnote: screeningNote?.trim(),
            };
            setData([{ ...data[0], candidatesdata: updatedCandidatesData }]);
          }
          handleClosePopup();
        })
        .catch((error) => {
          console.error(error);
        });
      // } else {
      //   setErrorMessage("Screening note is required.");
      // }
    }
  };

  const handleInputChange = (e) => {
    setScreeningNote(e.target.value);
  };
  const message = data[0]?.candidatesdata?.filter(
    (candidate) => candidate?.screeningnote
  );

  const messageStus = message?.map((items) => items);

  const toggleFilterDropdown = (selectedColumn) => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    // if (selectedFilter != selectedColumn) {
    setSelectedFilter(selectedColumn);
    // }
  };

  const filterSelectionChange = (data) => {
    switch (selectedFilter) {
      case "DE&I":
        setEthnicity((prevList) =>
          prevList.map((item) =>
            item.id === data.id ? { ...item, isChecked: !item.isChecked } : item
          )
        );
        break;
      case "NATIONALITY":
        setNationality((prevList) =>
          prevList.map((item) =>
            item.id === data.id ? { ...item, isChecked: !item.isChecked } : item
          )
        );
        break;
      case "CURRENT LOCATION":
        setCurrentLocation((prevList) =>
          prevList.map((item) =>
            item.id === data.id ? { ...item, isChecked: !item.isChecked } : item
          )
        );
        break;
      case "GENDER":
        setGender((prevList) =>
          prevList.map((item) =>
            item.id === data.id ? { ...item, isChecked: !item.isChecked } : item
          )
        );
        break;
      default:
        break;
    }
  };

  const onApplyFilter = () => {
    setSearchText("");
    switch (selectedFilter) {
      case "DE&I":
        const selectedEthnicity = ethnicity
          .filter((item) => item?.isChecked === true) // Only include items where 'selected' is true
          .map((item) => item?.id);

        const resultEthnicity = selectedEthnicity.join(",");
        updateFilter({
          search: "",
          selectedfilter: selectedFilter,
          filterlist: resultEthnicity ? resultEthnicity : "",
          sortby: "ethnicity",
          sortorder: "ASC",
        });
        // resultString ? setFilterList(resultString) : setFilterList("");
        setIsOpen(false);
        break;
      case "NATIONALITY":
        const selectedNationality = nationality
          .filter((item) => item?.isChecked === true) // Only include items where 'selected' is true
          .map((item) => item?.id);

        const resultNationality = selectedNationality.join(",");
        updateFilter({
          search: "",
          selectedfilter: selectedFilter,
          filterlist: resultNationality ? resultNationality : "",
          sortby: "nationality",
          sortorder: "ASC",
        });
        // resultString ? setFilterList(resultString) : setFilterList("");
        setIsOpen(false);
        break;
      case "CURRENT LOCATION":
        const selectedCurrLoc = currentLocation
          .filter((item) => item?.isChecked === true) // Only include items where 'selected' is true
          .map((item) => item?.id);

        const resultCurrLoc = selectedCurrLoc.join(",");
        updateFilter({
          search: "",
          selectedfilter: selectedFilter,
          filterlist: resultCurrLoc ? resultCurrLoc : "",
          sortby: "currentloc",
          sortorder: "ASC",
        });
        // resultString ? setFilterList(resultString) : setFilterList("");
        setIsOpen(false);
        break;
      case "GENDER":
        const selectedGender = gender
          .filter((item) => item?.isChecked === true) // Only include items where 'selected' is true
          .map((item) => item?.id);

        const resultGender = selectedGender.join(",");
        updateFilter({
          search: "",
          selectedfilter: selectedFilter,
          filterlist: resultGender ? resultGender : "",
          sortby: "gender",
          sortorder: "ASC",
        });
        // resultString ? setFilterList(resultString) : setFilterList("");
        setIsOpen(false);
        break;
      default:
        break;
    }
    switch (true) {
      case selectedFilter !== "DE&I":
        setEthnicity(ethnicity?.map((item) => ({ ...item, isChecked: false })));
        break;
      case selectedFilter !== "NATIONALITY":
        setNationality(
          nationality?.map((item) => ({ ...item, isChecked: false }))
        );
        break;
      case selectedFilter !== "CURRENT LOCATION":
        setCurrentLocation(
          currentLocation?.map((item) => ({ ...item, isChecked: false }))
        );
        break;
      case selectedFilter !== "GENDER":
        setGender(gender?.map((item) => ({ ...item, isChecked: false })));
        break;
      default:
        break;
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const clearSelection = () => {
    switch (true) {
      case selectedFilter == filters?.selectedfilter &&
        filters?.selectedfilter == "DE&I":
        // setFilterList("");
        setEthnicity(ethnicity?.map((item) => ({ ...item, isChecked: false })));
        updateFilter({
          sortby: "percentage",
          sortorder: "DESC",
          selectedfilter: "",
          filterlist: "",
        });
        setIsOpen(false);
        setSelectedFilter("");
        break;
      case selectedFilter == filters?.selectedfilter &&
        filters?.selectedfilter == "NATIONALITY":
        // setFilterList("");
        setNationality(
          nationality?.map((item) => ({ ...item, isChecked: false }))
        );
        updateFilter({
          sortby: "percentage",
          sortorder: "DESC",
          selectedfilter: "",
          filterlist: "",
        });
        setIsOpen(false);
        setSelectedFilter("");
        break;
      case selectedFilter == filters?.selectedfilter &&
        filters?.selectedfilter == "CURRENT LOCATION":
        // setFilterList("");
        setCurrentLocation(
          currentLocation?.map((item) => ({ ...item, isChecked: false }))
        );
        updateFilter({
          sortby: "percentage",
          sortorder: "DESC",
          selectedfilter: "",
          filterlist: "",
        });
        setIsOpen(false);
        setSelectedFilter("");
        break;
      case selectedFilter == filters?.selectedfilter &&
        filters?.selectedfilter == "GENDER":
        // setFilterList("");
        setGender(gender?.map((item) => ({ ...item, isChecked: false })));
        updateFilter({
          sortby: "percentage",
          sortorder: "DESC",
          selectedfilter: "",
          filterlist: "",
        });
        setIsOpen(false);
        setSelectedFilter("");
        break;
      default:
        break;
    }
    switch (selectedFilter) {
      case "DE&I":
        setEthnicity(ethnicity?.map((item) => ({ ...item, isChecked: false })));
        setIsOpen(false);
        break;
      case "NATIONALITY":
        setNationality(
          nationality?.map((item) => ({ ...item, isChecked: false }))
        );
        setIsOpen(false);
        break;
      case "CURRENT LOCATION":
        setCurrentLocation(
          currentLocation?.map((item) => ({ ...item, isChecked: false }))
        );
        setIsOpen(false);
        break;
      case "GENDER":
        setGender(gender?.map((item) => ({ ...item, isChecked: false })));
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const togglePhoneDrawer = () => {
    setOpenPhoneDrawer(!openPhoneDrawer);
  };

  const toggleSecondPhoneDrawer = () => {
    setOpenSecondPhoneDrawer(!openSecondPhoneDrawer);
  };

  return (
    <>
      <ToastContainer />
      <ScreeningMessagePopup
        analysisid={analysisid}
        screeningNote={screeningNote}
        handleInputChange={handleInputChange}
        handleSaveChanges={handleSaveChanges}
        handleClosePopup={handleClosePopup}
        isPopupOpen={isPopupOpen}
        errorMessage={errorMessage}
        oldNote={oldNote}
        isSave={isSave}
      />
      {isMobile ? (
        <>
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
            <title>Processed Candidate</title>
          </Helmet>
          <React.Fragment>
            <div className="sticky top-0 bg-[#F3F5F9] pb-1">
              <div className="flex flex-row justify-between bg-white p-[10px_20px] gap-4 shadow-md shadow-[rgba(91,147,237,0.2)]">
                <div className="flex flex-row gap-3 ">
                  <div className="cursor-pointer mt-1">
                    <img
                      onClick={() => window.history.back()}
                      src="/images/Back.svg"
                      alt="back"
                    />
                  </div>

                  <div className=" flex flex-row text-[#21d6aa] text-[15px] font-bold gap-2 cursor-pointer">
                    <p
                      onClick={() =>
                        navigate("/jobdescribtion", {
                          state: {
                            jobid: params?.jobid,
                            jobcode: params?.atsjobcode,
                          },
                        })
                      }
                    >
                      JOB ID: {params?.atsjobcode}
                    </p>
                    <text> ({data[0]?.candidatesdata?.length})</text>
                  </div>
                </div>
                <MarkFavorite
                  onClick={(e) =>
                    MarkJobFavoriteApi({
                      jobid: params?.jobid,
                      isfavorite: data[0]?.isfavorite,
                    })
                  }
                  imgUrl={
                    data[0]?.isfavorite
                      ? "/images/filled-bookmark.svg"
                      : "/images/bookmark.svg"
                  }
                />
              </div>

              <div className="search-container px-3 py-4">
                <input
                  type="text"
                  id="search"
                  placeholder="Search Candidate"
                  className="border-none outline-none h-10 pl-2 w-[200px] flex-1"
                  value={searchText}
                  onChange={searchTextChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") btnSearch();
                  }}
                />

                {searchText && (
                  <button
                    style={{
                      position: "absolute",
                      right: "60px",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={clearSearch}
                  >
                    <img
                      src="/images/greenCross1.svg"
                      style={{ width: "10px", height: "10px" }}
                      alt="Clear"
                    />
                  </button>
                )}

                <button
                  className="border-none outline-none h-10 pt-2"
                  type="submit"
                  onClick={() => btnSearch()}
                >
                  <img
                    className="bg-inherit"
                    src="/images/Search Icon.svg"
                    alt="Search"
                  />
                </button>
              </div>

              <div className="bg-white mx-3 bg-no-repeat rounded-md bg-cover p-2 flex flex-col gap-3">
                <div>
                  <p className="text-[18px] font-semibold">
                    {params?.jobtitle}
                  </p>
                  <p className="text-[12px] font-normal text-[#708EA4]">
                    {getOpenDayLabel(params?.opendays)}
                  </p>
                </div>
                <div className="flex flex-row justify-between text-[#21d6aa] text-[12px] font-semibold uppercase">
                  <div className="cursor-pointer" onClick={togglePhoneDrawer}>
                    {CvStatusfilterdata || "All"}
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={toggleSecondPhoneDrawer}
                  >
                    {filterdata || "Sort By"}
                  </div>
                </div>
              </div>
            </div>
            {noDataAvailable ? (
              <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                  <p className="text-[30px] text-gray-500 font-bold">!</p>
                </div>
                <p className="text-[20px] text-gray-500 font-bold">
                  Data not available
                </p>
              </div>
            ) : (
              <div className="!flex !flex-col mx-3">
                {data &&
                  data[0]?.candidatesdata?.map((candidate, i) => (
                    <div
                      className="bg-[#ffffff] rounded-md p-2 mt-2.5 flex flex-col gap-1"
                      key={candidate?.analysisid}
                    >
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row gap-2">
                          {candidate?.linkedinurl ? (
                            <Tooltip
                              title={
                                candidate?.linkedinurl?.length > 20
                                  ? candidate?.linkedinurl
                                  : ""
                              }
                            >
                              <a
                                href={
                                  candidate?.linkedinurl?.startsWith("http")
                                    ? candidate.linkedinurl
                                    : `https://${candidate?.linkedinurl}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="cursor-pointer"
                                  src="/images/linkedin.svg"
                                  alt="LinkedIn"
                                />
                              </a>
                            </Tooltip>
                          ) : null}
                          <Tooltip title="Download Resume">
                            <img
                              className="cursor-pointer"
                              src="/images/resumee.svg"
                              alt="resume"
                              onClick={() =>
                                downloadResume(candidate?.resumeurl)
                              }
                            />
                          </Tooltip>
                          <div className="w-6 cursor-pointer">
                            {candidate?.cvstatus && candidate?.screeningnote ? (
                              <img
                                onClick={() => handleItemClick(candidate)}
                                src="/images/screeningicon.svg"
                                alt="Screening Icon"
                              />
                            ) : (
                              candidate?.cvstatus === "Shortlisted" && (
                                <img
                                  onClick={() => handleItemClick(candidate)}
                                  src={
                                    candidate?.screeningnote === ""
                                      ? "/images/screeningicon2.svg"
                                      : "/images/screeningicon.svg"
                                  }
                                  alt="Screening Icon"
                                />
                              )
                            )}
                          </div>
                        </div>
                        <div className="cursor-pointer">
                          <Tooltip
                            title={
                              candidate?.isfavorite
                                ? "Favorite"
                                : "Mark as Favorite"
                            }
                          >
                            <div>
                              <MarkFavorite
                                onClick={(e) => btnMarkFavorite(candidate)}
                                imgUrl={
                                  candidate?.isfavorite
                                    ? "/images/filled-bookmark.svg"
                                    : "/images/bookmark.svg"
                                }
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>

                      <div className="flex flex-row justify-between">
                        <div className="flex flex-col text-sm text-gray-700 font-medium text-[15px]">
                          <Tooltip
                            title={
                              candidate?.candidatename?.length > 18
                                ? candidate.candidatename
                                : ""
                            }
                          >
                            {candidate?.candidatename.length > 18
                              ? `${candidate?.candidatename.substring(
                                  0,
                                  18
                                )}...`
                              : candidate?.candidatename}
                          </Tooltip>
                          <div className="text-[#708EA4] text-sm font-normal">
                            {formatDateTime(candidate?.createddate)}
                          </div>
                        </div>
                        <div>
                          <select
                            className="w-auto m-0 p-0 bg-transparent outline-none border text-gray-700  font-normal border-gray-400 rounded-md "
                            value={candidate?.cvstatus || "Select Status"}
                            onChange={(e) =>
                              handleStatusChange(
                                candidate?.analysisid,
                                e.target.value
                              )
                            }
                          >
                            {CvStatus?.map((status) => (
                              <option
                                key={status?.seqno}
                                value={status?.cvstatusname}
                              >
                                {status?.cvstatusname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-row justify-between text-sm text-gray-700 font-normal text-[15px]">
                        {customer?.isgender && (
                          <span>
                            {candidate?.gender === "M" ? "Male" : "Female"}
                          </span>
                        )}
                        <div className="flex flex-row gap-1">
                          <span>{candidate?.match_percentage}% </span>
                          <div
                            className="cursor-pointer"
                            onClick={(e) =>
                              navigate("/candidateresult", {
                                state: candidate?.analysisid,
                              })
                            }
                          >
                            <img src="/images/arrow.svg" alt="arrow" />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row justify-between text-sm text-gray-700 font-normal text-[15px]">
                        {customer?.show_deni && (
                          <div>{capitalizeWords(candidate?.ethnicity)}</div>
                        )}
                        <div>
                          {candidate?.totalexp === 0 ? (
                            <text className="flex justify-center w-full items-center  font-normal">
                              {" "}
                              -{" "}
                            </text>
                          ) : (
                            candidate?.totalexp.toFixed(1) + "y Exp."
                          )}
                        </div>
                      </div>

                      <div className="flex flex-row justify-between text-sm text-gray-700 font-normal text-[15px]">
                        {customer?.ismiddleeast && (
                          <div>
                            {customer?.ismiddleeast ? (
                              <p>
                                {" "}
                                {candidate?.nationality === "unknown"
                                  ? "-"
                                  : candidate?.nationality}
                              </p>
                            ) : (
                              <text className="flex justify-center w-[100px] items-center  font-normal">
                                {" "}
                                -{" "}
                              </text>
                            )}
                          </div>
                        )}
                        {customer?.isage && (
                          <div>{candidate?.age ? candidate?.age : "-"}</div>
                        )}
                      </div>

                      <div className="flex flex-raw gap-1 text-sm text-gray-700 font-normal text-[15px]">
                        <img src="/images/loction-icon.svg" alt="loction" />
                        {customer?.iscurrentjoblocation && (
                          <span>
                            {candidate?.currentjoblocation
                              ? candidate?.currentjoblocation
                              : "-"}
                          </span>
                        )}
                      </div>

                      <div className="flex flex-raw gap-1 text-sm text-gray-700 font-normal text-[15px]">
                        <img src="/images/phone-icon.svg" alt="phone" />
                        <span>{candidate?.phone ? candidate?.phone : "-"}</span>
                      </div>

                      <div className="flex flex-raw gap-1 text-sm text-gray-700 font-normal text-[15px]">
                        <img src="/images/email-icon.svg" alt="email" />
                        <span>{candidate?.email ? candidate?.email : "-"}</span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </React.Fragment>
          <React.Fragment>
            <Drawer
              anchor="bottom"
              open={openPhoneDrawer}
              onClose={togglePhoneDrawer}
              transitionDuration={500}
            >
              <Box
                sx={{ width: "100%", height: "50vh" }}
                role="presentation"
                onClick={togglePhoneDrawer}
              >
                <List className="px-3">
                  <div className="flex flex-row justify-end pt-3 ">
                    <img
                      src="/images/greenCross1.svg"
                      className="w-4 h-4"
                      alt="Clear"
                    />
                  </div>
                  <div className="flex flex-row justify-center text-[20px]  font-bold">
                    Status
                  </div>
                  <div className="pt-2">
                    {CvStatus &&
                      [
                        { cvstatusname: "All" }, // Add the "All" option as the first item
                        ...(CvStatus || []),
                      ]?.map((data, i) => (
                        <p
                          key={i}
                          className={`${
                            i % 2 === 0 ? "bg-[#EDF4FF]" : "bg-white"
                          } border-none p-2`}
                          onClick={() => handleThirdOptionClick(data)}
                        >
                          {data?.cvstatusname}
                        </p>
                      ))}
                  </div>
                </List>
              </Box>
            </Drawer>
          </React.Fragment>
          <React.Fragment>
            <Drawer
              anchor="bottom"
              open={openSecondPhoneDrawer}
              onClose={toggleSecondPhoneDrawer}
              transitionDuration={500}
            >
              <Box
                sx={{ width: "100%", height: "30vh" }}
                role="presentation"
                onClick={toggleSecondPhoneDrawer}
              >
                <List className="px-3">
                  <div className="flex flex-row justify-end pt-3 ">
                    <img
                      src="/images/greenCross1.svg"
                      className="w-4 h-4"
                      alt="Clear"
                    />
                  </div>
                  <div className="flex flex-row justify-center text-[20px]  font-bold">
                    Sort By
                  </div>
                  <div className="pt-2">
                    {SortData &&
                      SortData.map((data, i) => (
                        <p
                          key={i}
                          className={`${
                            i % 2 === 0 ? "bg-[#EDF4FF]" : "bg-white"
                          } border-none p-2`}
                          onClick={() => handleSecondOptionClick(data)}
                        >
                          {data?.name}
                        </p>
                      ))}
                  </div>
                </List>
              </Box>
            </Drawer>
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #FFFFFF;}"}</style>
            <title>Processed Candidate</title>
            {/* <meta name="description" content="App Description" /> */}
            {/* <meta name="theme-color" content="#008f68" /> */}
          </Helmet>
          <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
            <Navbar data={activetab} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: "80%" }}>
              <div class="analyst" className="flex ">
                <div className="w-[28%] ms-5 ">
                  <button onClick={() => navigate(-1)} className="p-match">
                    <img className="w-4" src="/images/arrow-left.svg" alt="" />
                    Back
                  </button>
                </div>
              </div>
              <div className="w-full flex flex-wrap justify-between items-center mt-2">
                <div className="flex flex-row gap-5 text-[#21d6aa] text-[14px] font-semibold ml-5">
                  <div className="notify-child flex gap-2">
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/jobdescribtion", {
                          state: {
                            jobid: params?.jobid,
                            jobcode: params?.atsjobcode,
                          },
                        })
                      }
                    >
                      JOB ID: {params?.atsjobcode}
                    </p>
                    <text> ({data[0]?.candidatesdata?.length})</text>
                  </div>

                  <div className="uppercase">{params?.jobtitle}</div>

                  {/* <Tooltip
                      title={
                        notificationActive
                          ? ""
                          : "Notify me when new candidates arrive"
                      }
                    >
                      <div
                        class="notify-child"
                        className="flex mt-2.5"
                        onClick={handleClick}
                      >
                        <img
                          src={
                            notificationActive
                              ? "/images/Notify Me Icondark.svg"
                              : "/images/Notify Me Icon.svg"
                          }
                          className="w-6 h-5 cursor-pointer"
                        />
                        <p className="ml-2 cursor-pointer">
                          {notificationActive
                            ? "NOTIFICATION ACTIVE "
                            : "NOTIFY ME"}
                        </p>
                      </div>
                    </Tooltip> */}
                  {/* {userType == "A" && (
                     <div className="mt-[9px] uppercase ">
                     <Link to="/errorresume" className="flex flex-row items-center gap-1 cursor-pointer">
                      <img
                         className="w-5"
                         src="/images/icon-warning.svg"
                         alt="error-icon"
                        />
                       <span>Unprocessed Resumes</span>
                     </Link>
                    </div>
                  )} */}
                  {userType == "A" && (
                    <div
                      className=" uppercase flex flex-row items-center gap-1 cursor-pointer"
                      onClick={() =>
                        navigate("/errorresume", {
                          state: params?.jobid,
                        })
                      }
                    >
                      <img
                        className="w-5"
                        src="/images/icon-warning.svg"
                        alt="error-icon"
                      />
                      <span>Unprocessed Resumes</span>
                    </div>
                  )}
                </div>
                <div className=" flex flex-row gap-2">
                  <div
                    className=" text-[#21d6aa] text-[14px] font-semibold flex gap-2 cursor-pointer items-center"
                    onClick={(e) =>
                      MarkJobFavoriteApi({
                        jobid: params?.jobid,
                        isfavorite: data[0]?.isfavorite,
                      })
                    }
                  >
                    <MarkFavorite
                      imgUrl={
                        data[0]?.isfavorite
                          ? "/images/filled-bookmark.svg"
                          : "/images/bookmark.svg"
                      }
                    />
                    <p>FAVORITE</p>
                  </div>
                  <div className="text-[#708ea4] text-[13px] font-normal">
                    <p>
                      {/* {data[0]?.opendays > 0
                        ? data[0]?.opendays > 1
                          ? "Opened " + data[0]?.opendays + " Days Ago"
                          : "Opened " + data[0]?.opendays + " Day Ago"
                        : "Opened Today"} */}
                      {getOpenDayLabel(params?.opendays)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-between mt-2 gap-3">
                <div className="search-container ml-[18px] mt-[10px] mb-[22px] relative flex items-center w-[500px]">
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Candidate"
                    className="border-none outline-none h-[40px] pl-[10px] w-[200px] flex-1"
                    value={searchText}
                    onChange={searchTextChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") btnSearch();
                    }}
                  />

                  {searchText && (
                    <button
                      style={{
                        position: "absolute",
                        right: "40px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      <img
                        className="w-2.5 h-2.5"
                        src="/images/greenCross1.svg"
                        alt="Clear"
                      />
                    </button>
                  )}

                  <button
                    className="border-none outline-none h-[40px] pt-2"
                    type="submit"
                    onClick={() => btnSearch()}
                  >
                    <img
                      className="bg-inherit"
                      src="/images/Search Icon.svg"
                      alt=""
                    />
                  </button>
                </div>
                <div className="flex flex-row justify-between  mt-3 gap-3">
                  <div
                    className="dropdown_sort_by"
                    style={{ width: "250px", zIndex: "99" }}
                    ref={thirdDropdownRef}
                  >
                    <button
                      onClick={toggleThirdDropdown}
                      className={`dropdown_sort ${
                        isthirdOpen ? "thirdopen" : ""
                      }`}
                    >
                      {CvStatusfilterdata || "All"}
                      {!isthirdOpen ? (
                        <img src="images/custom-arrow.svg" alt="" />
                      ) : (
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src="images/custom-arrow.svg"
                          alt=""
                        />
                      )}
                    </button>

                    {isthirdOpen && (
                      <div className="dropdown_sort_by_list">
                        <div>
                          {CvStatus &&
                            [
                              { cvstatusname: "All" }, // Add the "All" option as the first item
                              ...(CvStatus || []),
                            ]?.map((data, i) => (
                              <p
                                key={i}
                                onClick={() => handleThirdOptionClick(data)}
                              >
                                {data?.cvstatusname}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="dropdown_sort_by"
                    style={{ width: "250px", zIndex: "99" }}
                    ref={secondDropdownRef}
                  >
                    <button
                      onClick={toggleSecondDropdown}
                      className={`dropdown_sort ${
                        isSecondOpen ? "secondopen" : ""
                      }`}
                    >
                      {filterdata || "Sort By"}
                      {!isSecondOpen ? (
                        <img src="images/custom-arrow.svg" alt="" />
                      ) : (
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src="images/custom-arrow.svg"
                          alt=""
                        />
                      )}
                    </button>

                    {isSecondOpen && (
                      <div className="dropdown_sort_by_list">
                        <div>
                          {SortData &&
                            SortData.map((data, i) => (
                              <p
                                key={i}
                                onClick={() => handleSecondOptionClick(data)}
                              >
                                {data?.name}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div
                      class="justify-center w-34 h-10  flex flex-raw"
                      style={{ boxShadow: "0px 0px 20px #5B93ED33" }}
                    >
                      <Tooltip title={"Summary View"}>
                        <button
                          className="w-[42px] flex items-center justify-center"
                          onClick={() => buttonHandle2()}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              !toggleView && !detailsView ? "#21D6AA" : "white",
                          }}
                        >
                          {!toggleView && !detailsView ? (
                            <img
                              className="w-5 "
                              src="/images/white-grid.svg"
                              alt="Button 2"
                            />
                          ) : (
                            <img
                              className="w-5 "
                              src="/images/4-box.svg"
                              alt="Button 2"
                            />
                          )}
                        </button>
                      </Tooltip>
                      {customer?.isdetailview === true && (
                        <Tooltip title={"Detailed View"}>
                          <button
                            className="w-[42px] flex items-center justify-center"
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                detailsView === true ? "#21D6AA" : "white",
                            }}
                            onClick={() => buttonHandle()}
                          >
                            {detailsView ? (
                              <img
                                className="w-12 "
                                src="/images/viewdetails2.svg"
                                alt="Button 1"
                              />
                            ) : (
                              <img
                                className="w-12"
                                src="/images/viewdetails.svg"
                                alt="Button 1"
                              />
                            )}
                          </button>
                        </Tooltip>
                      )}
                      {/* <Tooltip title={" List View"}>
                        <button
                          className="w-[42px] flex items-center justify-center"
                          onClick={() => buttonHandle2()}
                          style={{
                            cursor: "pointer",
                            backgroundColor: toggleView ? "#21D6AA" : "white",
                          }}
                        >
                          {toggleView ? (
                            <img
                              className="w-5 "
                              src="/images/3-dots.svg"
                              alt="Button 1"
                            />
                          ) : (
                            <img
                              className="w-5 "
                              src="/images/green-list.svg"
                              alt="Button 1"
                            />
                          )}
                        </button>
                      </Tooltip> */}
                    </div>
                  </div>
                </div>
              </div>

              {/*listing */}
              {detailsView ? (
                noDataAvailable ? (
                  <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                    <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                      <p className="text-[30px] text-gray-500 font-bold">!</p>
                    </div>
                    <p className="text-[20px] text-gray-500 font-bold">
                      Data not available
                    </p>
                  </div>
                ) : (
                  <>
                    <div
                      className="2xl:min-w-[100%]   overflow-y-hidden ms-5"
                      style={{
                        scrollbarWidth: "thin",
                        scrollbarColor: "auto transparent",
                      }}
                    >
                      <div
                        className={`overflow-auto `}
                        style={{
                          width: `${tableWidth + 100}px`,
                          scrollbarWidth: "thin",
                          scrollbarColor: "auto transparent",
                        }}
                      >
                        <table className="w-full bg-white border border-[#E0E0E0] rounded-sm divide-gray-200">
                          <thead>
                            <tr>
                              <th className="!w-[40px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"></th>

                              <th className="!w-[12px]  text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"></th>

                              <th className="!w-[125px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                Status
                              </th>
                              <th
                                className={`!w-[130px] lg:w-[128px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase`}
                              >
                                Candidate{" "}
                                <span
                                  className="inline-flex items-center cursor-pointer w-4 h-4"
                                  onClick={() => {
                                    updateFilter({
                                      sortby: "atoz",
                                      sortorder:
                                        filters?.sortby == "atoz"
                                          ? filters?.sortorder === "ASC"
                                            ? "DESC"
                                            : "ASC"
                                          : "ASC",
                                    });
                                    setFilterdata("Sort By");
                                  }}
                                >
                                  {filters?.sortby != "atoz" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                    />
                                  ) : filters?.sortorder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                    />
                                  )}
                                </span>
                              </th>
                              <th className="!w-[86px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                MATCH %{" "}
                                <span
                                  className="inline-flex items-center cursor-pointer w-4 h-4"
                                  onClick={() => {
                                    updateFilter({
                                      sortby: "percentage",
                                      sortorder:
                                        filters?.sortby == "percentage"
                                          ? filters?.sortorder === "ASC"
                                            ? "DESC"
                                            : "ASC"
                                          : "ASC",
                                    });
                                    setFilterdata("Sort By");
                                  }}
                                >
                                  {filters?.sortby != "percentage" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                    />
                                  ) : filters?.sortorder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                    />
                                  )}
                                </span>
                              </th>

                              {customer?.show_deni && (
                                <th className="!w-[110px] px-2 py-4 text-left font-medium text-blue 2xl:text-[16px] lg:text-[13px] xl:text-[15px] uppercase">
                                  DE&I{" "}
                                  <div className="flex-row inline-flex items-center cursor-pointer gap-0.5">
                                    <span
                                      onClick={() => {
                                        updateFilter({
                                          sortby: "ethnicity",
                                          sortorder:
                                            filters?.sortby == "ethnicity"
                                              ? filters?.sortorder === "ASC"
                                                ? "DESC"
                                                : "ASC"
                                              : "ASC",
                                        });
                                        setFilterdata("Sort By");
                                      }}
                                    >
                                      {filters?.sortby != "ethnicity" ? (
                                        <img
                                          src="/images/descendant.svg"
                                          alt="Sort"
                                          className="w-4 h-4"
                                        />
                                      ) : filters?.sortorder === "DESC" ? (
                                        <img
                                          src="/images/asc.svg"
                                          alt="Ascending"
                                          className="w-4 h-4"
                                        />
                                      ) : (
                                        <img
                                          src="/images/desc.svg"
                                          alt="Descending"
                                          className="w-4 h-4"
                                        />
                                      )}
                                    </span>
                                    <div className="relative inline-block">
                                      <img
                                        src={
                                          filters?.selectedfilter == "DE&I"
                                            ? "/images/filter-icon-active.svg"
                                            : "/images/filter-icon.svg"
                                        }
                                        alt="Filter"
                                        className="w-4 h-4"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleFilterDropdown("DE&I");
                                        }}
                                      />
                                      {isOpen && selectedFilter == "DE&I" && (
                                        <CandidateFilter
                                          filterList={ethnicity}
                                          filterSelectionChange={
                                            filterSelectionChange
                                          }
                                          selectedFilter={selectedFilter}
                                          onApplyFilter={onApplyFilter}
                                          clearSelection={clearSelection}
                                          setIsOpen={setIsOpen}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </th>
                              )}
                              {customer?.ismiddleeast && (
                                <th
                                  className={`!w-[95px] px-2 py-4 text-left font-medium text-blue 2xl:text-[16px] lg:text-[13px] xl:text-[15px] uppercase`}
                                >
                                  NATIONAL{" "}
                                  <div className="flex-row inline-flex items-center cursor-pointer gap-0.5">
                                    <span
                                      onClick={() => {
                                        updateFilter({
                                          sortby: "nationality",
                                          sortorder:
                                            filters?.sortby == "nationality"
                                              ? filters?.sortorder === "ASC"
                                                ? "DESC"
                                                : "ASC"
                                              : "ASC",
                                        });
                                        setFilterdata("Sort By");
                                      }}
                                    >
                                      {filters?.sortby != "nationality" ? (
                                        <img
                                          src="/images/descendant.svg"
                                          alt="Sort"
                                          className="w-4 h-4"
                                        />
                                      ) : filters?.sortorder === "DESC" ? (
                                        <img
                                          src="/images/asc.svg"
                                          alt="Ascending"
                                          className="w-4 h-4"
                                        />
                                      ) : (
                                        <img
                                          src="/images/desc.svg"
                                          alt="Descending"
                                          className="w-4 h-4"
                                        />
                                      )}
                                    </span>
                                    <div className="relative inline-block">
                                      <img
                                        src={
                                          filters?.selectedfilter ==
                                          "NATIONALITY"
                                            ? "/images/filter-icon-active.svg"
                                            : "/images/filter-icon.svg"
                                        }
                                        alt="Filter"
                                        className="w-4 h-4"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleFilterDropdown("NATIONALITY");
                                        }}
                                      />
                                      {isOpen &&
                                        selectedFilter == "NATIONALITY" && (
                                          <CandidateFilter
                                            filterList={nationality}
                                            filterSelectionChange={
                                              filterSelectionChange
                                            }
                                            selectedFilter={selectedFilter}
                                            onApplyFilter={onApplyFilter}
                                            clearSelection={clearSelection}
                                            setIsOpen={setIsOpen}
                                          />
                                        )}
                                    </div>
                                  </div>
                                </th>
                              )}
                              {customer?.isgender && (
                                <th className="!w-[80px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  GENDER{" "}
                                  <div className="flex-row inline-flex items-center cursor-pointer gap-0.5">
                                    <span
                                      onClick={() => {
                                        updateFilter({
                                          sortby: "gender",
                                          sortorder:
                                            filters?.sortby == "gender"
                                              ? filters?.sortorder === "ASC"
                                                ? "DESC"
                                                : "ASC"
                                              : "ASC",
                                        });
                                        setFilterdata("Sort By");
                                      }}
                                    >
                                      {filters?.sortby != "gender" ? (
                                        <img
                                          src="/images/descendant.svg"
                                          alt="Sort"
                                          className="w-4 h-4"
                                        />
                                      ) : filters?.sortorder === "DESC" ? (
                                        <img
                                          src="/images/asc.svg"
                                          alt="Ascending"
                                          className="w-4 h-4"
                                        />
                                      ) : (
                                        <img
                                          src="/images/desc.svg"
                                          alt="Descending"
                                          className="w-4 h-4"
                                        />
                                      )}
                                    </span>
                                    <div className="relative inline-block">
                                      <img
                                        src={
                                          filters?.selectedfilter == "GENDER"
                                            ? "/images/filter-icon-active.svg"
                                            : "/images/filter-icon.svg"
                                        }
                                        alt="Filter"
                                        className="w-4 h-4"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleFilterDropdown("GENDER");
                                        }}
                                      />
                                      {isOpen && selectedFilter == "GENDER" && (
                                        <CandidateFilter
                                          filterList={gender}
                                          filterSelectionChange={
                                            filterSelectionChange
                                          }
                                          selectedFilter={selectedFilter}
                                          onApplyFilter={onApplyFilter}
                                          clearSelection={clearSelection}
                                          setIsOpen={setIsOpen}
                                          isSearchVisible={false}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </th>
                              )}
                              {customer?.iscurrentjoblocation && (
                                <th className="!w-[140px] px-2 py-4 text-left font-medium text-blue 2xl:text-[16px] lg:text-[13px] xl:text-[15px] uppercase">
                                  Current Location{" "}
                                  <div className="flex-row inline-flex items-center cursor-pointer gap-0.5">
                                    <span
                                      onClick={() => {
                                        updateFilter({
                                          sortby: "currentloc",
                                          sortorder:
                                            filters?.sortby == "currentloc"
                                              ? filters?.sortorder === "ASC"
                                                ? "DESC"
                                                : "ASC"
                                              : "ASC",
                                        });
                                        setFilterdata("Sort By");
                                      }}
                                    >
                                      {filters?.sortby != "currentloc" ? (
                                        <img
                                          src="/images/descendant.svg"
                                          alt="Sort"
                                          className="w-4 h-4"
                                        />
                                      ) : filters?.sortorder === "DESC" ? (
                                        <img
                                          src="/images/asc.svg"
                                          alt="Ascending"
                                          className="w-4 h-4"
                                        />
                                      ) : (
                                        <img
                                          src="/images/desc.svg"
                                          alt="Descending"
                                          className="w-4 h-4"
                                        />
                                      )}
                                    </span>
                                    <div className="relative inline-block">
                                      <img
                                        src={
                                          filters?.selectedfilter ==
                                          "CURRENT LOCATION"
                                            ? "/images/filter-icon-active.svg"
                                            : "/images/filter-icon.svg"
                                        }
                                        alt="Filter"
                                        className="w-4 h-4"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleFilterDropdown(
                                            "CURRENT LOCATION"
                                          );
                                        }}
                                      />
                                      {isOpen &&
                                        selectedFilter ==
                                          "CURRENT LOCATION" && (
                                          <CandidateFilter
                                            filterList={currentLocation}
                                            filterSelectionChange={
                                              filterSelectionChange
                                            }
                                            selectedFilter={selectedFilter}
                                            onApplyFilter={onApplyFilter}
                                            clearSelection={clearSelection}
                                            setIsOpen={setIsOpen}
                                          />
                                        )}
                                    </div>
                                  </div>
                                </th>
                              )}
                              <th className="!w-[90px] px-2 py-4 text-center font-medium text-blue 2xl:text-[16px] lg:text-[13px] xl:text-[15px] uppercase">
                                TOTAL EXP{" "}
                                <span
                                  className="inline-flex items-center cursor-pointer w-4 h-4"
                                  onClick={() => {
                                    updateFilter({
                                      sortby: "totalexp",
                                      sortorder:
                                        filters?.sortby == "totalexp"
                                          ? filters?.sortorder === "ASC"
                                            ? "DESC"
                                            : "ASC"
                                          : "ASC",
                                    });
                                    setFilterdata("Sort By");
                                  }}
                                >
                                  {filters?.sortby != "totalexp" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                      className="w-4 h-4"
                                    />
                                  ) : filters?.sortorder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                      className="w-4 h-4"
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                      className="w-4 h-4"
                                    />
                                  )}
                                </span>
                              </th>
                              <th className="!w-[130px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                CONTACT
                              </th>
                              <th className="!w-[180px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                EMAIL
                              </th>
                              <th className="!w-[180px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                LINKEDIN
                              </th>
                              {customer?.isexperiencesimiliar && (
                                <th className="!w-[120px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Similar Industry
                                </th>
                              )}
                              {customer?.iscustomcriticalskill && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Critical Skill
                                </th>
                              )}
                              {customer?.ishighestqualification && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Highest Edu.
                                </th>
                              )}
                              {customer?.isdrivinglic && (
                                <th className="!w-[120px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Driving License
                                </th>
                              )}
                              {customer?.isarabicspeaking && (
                                <th className="!w-[120px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  SPEAKS ARABIC
                                </th>
                              )}
                              {customer?.isoverqualified && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Over Qual.
                                </th>
                              )}
                              {customer?.isoverexperienced && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Over Exp.
                                </th>
                              )}
                              {customer?.isage && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Age
                                </th>
                              )}
                              <th className="!w-[40px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                {}
                              </th>

                              {data[0]?.candidatesdata?.length > 5 && (
                                <th className="!w-[5px]  text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  {}
                                </th>
                              )}
                            </tr>
                          </thead>
                        </table>

                        <div
                          className={` ${
                            noDataAvailable
                              ? "max-h-[calc(100vh-350px)]"
                              : "max-h-[calc(100vh-305px)]"
                          }  overflow-auto shadow-md `}
                          style={{
                            scrollbarWidth: "thin",
                            scrollbarColor: "auto transparent",
                          }}
                        >
                          <table className="w-full border-l border- border-[#E0E0E0] rounded-sm ">
                            <tbody>
                              {data &&
                                data[0]?.candidatesdata?.map((candidate, i) => (
                                  <tr
                                    key={i}
                                    className={`${
                                      i % 2 === 0
                                        ? "bg-[#EDF4FF] border-none"
                                        : "bg-white border-none"
                                    }`}
                                  >
                                    <td className=" !w-[40px] text-center px-2 py-3  text-sm text-gray-700 font-medium text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.isfavorite
                                            ? "Favorite"
                                            : "Mark as Favorite"
                                        }
                                      >
                                        <div>
                                          <MarkFavorite
                                            onClick={(e) =>
                                              btnMarkFavorite(candidate)
                                            }
                                            imgUrl={
                                              candidate?.isfavorite
                                                ? "/images/filled-bookmark.svg"
                                                : "/images/bookmark.svg"
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </td>
                                    <td className=" !w-[12px] text-center text-[#21D6AA] ">
                                      {candidate?.cvstatus &&
                                      candidate?.screeningnote ? (
                                        <Tooltip title="View Screening Note">
                                          <img
                                            onClick={() =>
                                              handleItemClick(candidate)
                                            }
                                            className="w-7 h-7 cursor-pointer"
                                            src="/images/screeningicon.svg"
                                            alt="Screening Icon"
                                          />
                                        </Tooltip>
                                      ) : (
                                        candidate?.cvstatus ===
                                          "Shortlisted" && (
                                          <Tooltip
                                            title={
                                              candidate?.screeningnote === ""
                                                ? "Enter Screening Note"
                                                : "View Screening Note"
                                            }
                                          >
                                            <img
                                              onClick={() =>
                                                handleItemClick(candidate)
                                              }
                                              className="w-7 h-7 cursor-pointer"
                                              src={
                                                candidate?.screeningnote === ""
                                                  ? "/images/screeningicon2.svg"
                                                  : "/images/screeningicon.svg"
                                              }
                                              alt="Screening Icon"
                                            />
                                          </Tooltip>
                                        )
                                      )}
                                    </td>

                                    <td className="!w-[125px] text-left px-2 py-3 text-[15px]  font-normal ">
                                      <select
                                        className="w-auto m-0 p-0 bg-transparent border text-gray-700  font-normal border-gray-400 rounded-md "
                                        value={
                                          candidate?.cvstatus || "Select Status"
                                        }
                                        onChange={(e) =>
                                          handleStatusChange(
                                            candidate?.analysisid,
                                            e.target.value
                                          )
                                        }
                                      >
                                        {CvStatus?.map((status) => (
                                          <option
                                            key={status?.seqno}
                                            value={status?.cvstatusname}
                                          >
                                            {status?.cvstatusname}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td className=" !w-[130px]  text-left px-2 py-3  text-sm text-gray-700 font-medium text-[15px]">
                                      <div className="flex flex-col gap-1">
                                        <div className="w-full flex justify-between">
                                          <Tooltip
                                            title={
                                              candidate?.candidatename?.length >
                                              18
                                                ? candidate.candidatename
                                                : ""
                                            }
                                          >
                                            {candidate?.candidatename.length >
                                            18
                                              ? `${candidate?.candidatename.substring(
                                                  0,
                                                  18
                                                )}...`
                                              : candidate?.candidatename}
                                          </Tooltip>
                                          <div
                                            className="cursor-pointer"
                                            onClick={(e) =>
                                              navigate("/candidateresult", {
                                                state: candidate?.analysisid,
                                              })
                                            }
                                          >
                                            <Tooltip title="Candidate Result">
                                              <img src="/images/arrow.svg" />
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="text-[#708EA4] text-sm font-normal">
                                          {formatDateTime(
                                            candidate?.createddate
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    <td className=" !w-[86px]  px-2 py-3 text-center text-sm text-gray-700 font-normal text-[15px]">
                                      {candidate?.match_percentage}%{" "}
                                    </td>

                                    {customer?.show_deni && (
                                      <td className=" !w-[108px] text-left px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        <Tooltip
                                          title={
                                            candidate?.ethnicity?.length > 14
                                              ? capitalizeWords(
                                                  candidate?.ethnicity
                                                )
                                              : ""
                                          }
                                        >
                                          {candidate?.ethnicity === "unknown"
                                            ? "-"
                                            : candidate?.ethnicity?.length > 14
                                            ? `${capitalizeWords(
                                                candidate?.ethnicity.substring(
                                                  0,
                                                  14
                                                )
                                              )}...`
                                            : capitalizeWords(
                                                candidate?.ethnicity
                                              )}
                                        </Tooltip>
                                      </td>
                                    )}
                                    {customer?.ismiddleeast && (
                                      <td
                                        className={` !w-[100px] text-left px-2 py-3  font-normal text-[15px] text-gray-700`}
                                      >
                                        {customer?.ismiddleeast ? (
                                          <Tooltip
                                            title={
                                              candidate?.nationality?.length >
                                              15
                                                ? candidate?.nationality
                                                : ""
                                            }
                                          >
                                            {" "}
                                            {candidate?.nationality ===
                                            "unknown"
                                              ? "-"
                                              : candidate?.nationality?.length >
                                                15
                                              ? `${candidate?.nationality.substring(
                                                  0,
                                                  15
                                                )}...`
                                              : candidate?.nationality}
                                          </Tooltip>
                                        ) : (
                                          <text className="flex justify-center w-[100px] items-center  font-normal">
                                            {" "}
                                            -{" "}
                                          </text>
                                        )}
                                      </td>
                                    )}
                                    {customer?.isgender && (
                                      <td className="!w-[80px] text-left   cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.gender === "M"
                                          ? "Male"
                                          : "Female"}
                                      </td>
                                    )}
                                    {customer?.iscurrentjoblocation && (
                                      <td className=" !w-[130px] text-left cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        <Tooltip
                                          title={
                                            candidate?.currentjoblocation
                                              ?.length > 20
                                              ? candidate?.currentjoblocation
                                              : ""
                                          }
                                        >
                                          {candidate?.currentjoblocation
                                            ?.length > 20
                                            ? `${candidate?.currentjoblocation.substring(
                                                0,
                                                20
                                              )}...`
                                            : candidate?.currentjoblocation
                                            ? candidate?.currentjoblocation
                                            : ""}
                                        </Tooltip>
                                      </td>
                                    )}
                                    <td className=" !w-[90px] text-center   cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      {candidate?.totalexp === 0 ? (
                                        <text className="flex justify-center w-full items-center  font-normal">
                                          {" "}
                                          -{" "}
                                        </text>
                                      ) : (
                                        candidate?.totalexp.toFixed(1) + "y"
                                      )}
                                    </td>
                                    <td className="!w-[130px] text-left cursor-paste px-2  py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.phone?.length > 14
                                            ? candidate?.phone
                                            : ""
                                        }
                                      >
                                        {candidate?.phone?.length > 14
                                          ? `${candidate?.phone.substring(
                                              0,
                                              14
                                            )}...`
                                          : candidate?.phone
                                          ? candidate?.phone
                                          : ""}
                                      </Tooltip>
                                    </td>
                                    <td className="!w-[180px] text-left px-2 cursor-paste py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.email?.length > 18
                                            ? candidate?.email
                                            : ""
                                        }
                                      >
                                        {candidate?.email?.length > 18
                                          ? `${candidate?.email.substring(
                                              0,
                                              18
                                            )}...`
                                          : candidate?.email
                                          ? candidate?.email
                                          : ""}
                                      </Tooltip>
                                    </td>
                                    <td className="!w-[180px] text-left items-center cursor-paste px-2 py-3 text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.linkedinurl?.length > 20
                                            ? candidate?.linkedinurl
                                            : ""
                                        }
                                      >
                                        <a
                                          href={
                                            candidate?.linkedinurl?.startsWith(
                                              "http"
                                            )
                                              ? candidate.linkedinurl
                                              : `https://${candidate?.linkedinurl}`
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-blue"
                                        >
                                          {candidate.linkedinurl?.length > 20
                                            ? `${candidate?.linkedinurl?.substring(
                                                0,
                                                20
                                              )}...`
                                            : candidate?.linkedinurl
                                            ? candidate?.linkedinurl
                                            : ""}
                                        </a>
                                      </Tooltip>
                                    </td>
                                    {customer?.isexperiencesimiliar && (
                                      <td className=" !w-[120px]  text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.isexpinsimilarindustry ===
                                        true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {customer?.iscustomcriticalskill && (
                                      <td className=" !w-[100px]  text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.iscustomcriticalskillyes ===
                                        true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {customer?.ishighestqualification && (
                                      <td className=" !w-[100px] items-center text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.highestqualification ? (
                                          candidate?.highestqualification
                                        ) : (
                                          <div className="!w-full flex justify-center">
                                            {" "}
                                            -{" "}
                                          </div>
                                        )}
                                      </td>
                                    )}
                                    {customer?.isdrivinglic && (
                                      <td className=" !w-[120px] text-center cursor-paste px-2 py-3 text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.isdrivinglic === true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {customer?.isarabicspeaking && (
                                      <td className="!w-[120px] text-center items-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.isarabicspeaking === true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {/* { customer?.ismiddleeast && ( */}
                                    {customer?.isoverqualified && (
                                      <td
                                        className={` !w-[100px]  items-center text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]`}
                                      >
                                        {candidate?.isoverqualified === true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {/* )} */}
                                    {customer?.isoverexperienced && (
                                      <td className=" !w-[100px] items-center text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.isoverexperienced === true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {customer?.isage && (
                                      <td className=" !w-[100px] items-center text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.age ? candidate?.age : "-"}
                                      </td>
                                    )}
                                    <td className="!w-[40px] px-2 py-3 text-[15px]   items-center font-medium text-[#21D6AA]">
                                      <Tooltip title="Download Resume">
                                        <img
                                          className=" items-center cursor-pointer"
                                          src="/images/resumee.svg"
                                          alt="resume"
                                          onClick={() =>
                                            downloadResume(candidate?.resumeurl)
                                          }
                                        />
                                      </Tooltip>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : !toggleView ? (
                <>
                  {noDataAvailable ? (
                    <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                      <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                        <p className="text-[30px] text-gray-500 font-bold">!</p>
                      </div>
                      <p className="text-[20px] text-gray-500 font-bold">
                        Data not available
                      </p>
                    </div>
                  ) : (
                    <div
                      className="mx-[15px] max-h-[calc(100vh-240px)] overflow-auto "
                      style={{ scrollbarWidth: "thin", overflowX: "hidden" }}
                    >
                      <div class="full-list">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {data &&
                            data[0]?.candidatesdata?.map((candidate, i) => (
                              <div
                                className="flex flex-row justify-between bg-[#edf4ff] rounded-lg p-2 mt-2"
                                key={candidate?.analysisid}
                                style={{
                                  width: "32%",
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <div
                                  class="under-listing "
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    navigate("/candidateresult", {
                                      state: candidate?.analysisid,
                                    })
                                  }
                                >
                                  <p>{candidate?.candidatename}</p>
                                </div>
                                <div
                                  class="under-listing"
                                  style={{ textAlign: "center" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#708EA4",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "paste",
                                      }}
                                      onClick={(e) =>
                                        navigate("/candidateresult", {
                                          state: candidate?.analysisid,
                                        })
                                      }
                                    >
                                      {candidate?.match_percentage}%
                                    </p>
                                    <img
                                      style={{
                                        width: "20px",
                                        cursor: "pointer",
                                      }}
                                      src="/images/arrow.svg"
                                      alt=""
                                      onClick={(e) =>
                                        navigate("/candidateresult", {
                                          state: candidate?.analysisid,
                                        })
                                      }
                                    />
                                    <Tooltip
                                      title={
                                        candidate?.isfavorite
                                          ? "Favorite"
                                          : "Mark as Favorite"
                                      }
                                    >
                                      <div>
                                        <MarkFavorite
                                          onClick={(e) =>
                                            btnMarkFavorite(candidate)
                                          }
                                          imgUrl={
                                            candidate?.isfavorite
                                              ? "/images/filled-bookmark.svg"
                                              : "/images/bookmark.svg"
                                          }
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {noDataAvailable ? (
                    <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                      <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                        <p className="text-[30px] text-gray-500 font-bold">!</p>
                      </div>
                      <p className="text-[20px] text-gray-500 font-bold">
                        Data not available
                      </p>
                    </div>
                  ) : (
                    <div
                      className="mx-[15px] shadow-md "
                      style={{ boxShadow: "0px 0px 20px #5B93ED33" }}
                    >
                      <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0]">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr className="w-full">
                              <th className="2xl:w-[40px] xl:w-[30px] lg:w-[30px] py-4 text-left font-medium text-blue   text-[16px] uppercase"></th>
                              <th className="2xl:w-[110px] xl:w-[90px] lg:w-[90px]   py-4 text-left font-medium text-blue   text-[16px] first-line:uppercase">
                                CANDIDATE
                              </th>
                              <th className="2xl:w-[85px] xl:w-[70px] lg:w-[70px] py-4 text-left font-medium text-blue   text-[16px] uppercase">
                                MATCH&nbsp;%
                              </th>
                              <th className="2xl:w-[90px] xl:w-[50px] lg:w-[50px] py-4 text-left font-medium text-blue   text-[16px] uppercase">
                                RESULT
                              </th>
                              {customer?.show_deni && (
                                <th className="2xl:w-[75px] xl:w-[50px] lg:w-[50px]  py-4 text-left font-medium text-blue   text-[16px] uppercase">
                                  DE&I
                                </th>
                              )}
                              {customer?.ismiddleeast && (
                                <th className="2xl:w-[110px] xl:w-[70px] lg:w-[70px]    py-4 text-left font-medium text-blue   text-[16px] uppercase">
                                  NATIONALITY
                                </th>
                              )}
                              <th className="2xl:w-[80px] xl:w-[50px] lg:w-[50px]  py-4 text-left font-medium text-blue text-[16px] uppercase">
                                AGE
                              </th>
                              <th className="2xl:w-[120px] xl:w-[90px] lg:w-[90px] py-4 text-left font-medium text-blue text-[16px] uppercase">
                                EMAIL
                              </th>
                              <th className="2xl:w-[160px] xl:w-[200px] lg:w-[220px] py-4 text-left font-medium text-blue text-[16px] uppercase"></th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  )}
                  <div
                    className={`mx-[15px] ${
                      noDataAvailable
                        ? "max-h-[calc(100vh-250px)]"
                        : "max-h-[calc(100vh-305px)]"
                    }  overflow-auto shadow-md `}
                    style={{ scrollbarWidth: "none" }}
                  >
                    <div className="">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                          {data &&
                            data[0]?.candidatesdata?.map((candidate, i) => (
                              <tr
                                key={i}
                                className={`${
                                  i % 2 === 0
                                    ? "bg-[#EDF4FF] border-none"
                                    : "bg-white border-none"
                                }`}
                              >
                                <td className="w-[4%] px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  <div>
                                    <Tooltip
                                      title={
                                        candidate?.isfavorite
                                          ? "Favorite"
                                          : "Mark as Favorite"
                                      }
                                    >
                                      <div>
                                        <MarkFavorite
                                          onClick={(e) =>
                                            btnMarkFavorite(candidate)
                                          }
                                          imgUrl={
                                            candidate?.isfavorite
                                              ? "/images/filled-bookmark.svg"
                                              : "/images/bookmark.svg"
                                          }
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td className="w-[13%] px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  <div>
                                    <div className="flex flex-col gap-3">
                                      <Tooltip
                                        title={
                                          candidate?.candidatename?.length > 12
                                            ? candidate?.candidatename
                                            : ""
                                        }
                                      >
                                        {candidate?.candidatename?.length > 12
                                          ? `${candidate?.candidatename.substring(
                                              0,
                                              12
                                            )}...`
                                          : candidate?.candidatename}
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          candidate?.phone.length > 20
                                            ? candidate.phone
                                            : ""
                                        }
                                      >
                                        {candidate?.phone.length > 20
                                          ? `${candidate.phone.substring(
                                              0,
                                              20
                                            )}...`
                                          : candidate?.phone}
                                      </Tooltip>
                                    </div>
                                  </div>
                                </td>

                                <td
                                  className={`${
                                    customer?.show_deni ? "w-[10%]" : "w-[10%]"
                                  } px-2  py-4 text-[15px] text-[#708EA4]`}
                                >
                                  {candidate?.match_percentage}%
                                </td>

                                <td
                                  className="w-[10%] px-2  py-4 text-[15px] font-medium text-[#21D6AA] cursor-pointer"
                                  onClick={(e) =>
                                    navigate("/candidateresult", {
                                      state: candidate?.analysisid,
                                    })
                                  }
                                >
                                  <div className="flex flex-row gap-1">
                                    <p>VIEW</p>
                                    <img src="/images/arrow.svg" alt="" />
                                  </div>
                                </td>
                                {customer?.show_deni && (
                                  <td className="w-[9%] cursor-pointer px-2  py-4 text-sm text-gray-700 font-medium text-[15px]">
                                    <Tooltip
                                      title={
                                        candidate?.ethnicity?.length > 14
                                          ? capitalizeWords(
                                              candidate?.ethnicity
                                            )
                                          : ""
                                      }
                                    >
                                      {candidate?.ethnicity?.length > 14
                                        ? `${capitalizeWords(
                                            candidate?.ethnicity.substring(
                                              0,
                                              14
                                            )
                                          )}...`
                                        : capitalizeWords(candidate?.ethnicity)}
                                    </Tooltip>
                                  </td>
                                )}

                                {/* { customer?.ismiddleeast && ( */}
                                {customer?.ismiddleeast && (
                                  <td
                                    className={` ${
                                      customer?.show_deni
                                        ? "w-[13%]"
                                        : "w-[13%]"
                                    } cursor-paste px-2  py-4  text-sm text-gray-700 font-medium text-[15px]`}
                                  >
                                    {customer?.ismiddleeast ? (
                                      <Tooltip
                                        title={
                                          candidate?.nationality?.length > 15
                                            ? candidate?.nationality
                                            : ""
                                        }
                                      >
                                        {" "}
                                        {candidate?.nationality?.length > 15
                                          ? `${candidate?.nationality.substring(
                                              0,
                                              15
                                            )}...`
                                          : candidate?.nationality}
                                      </Tooltip>
                                    ) : (
                                      <text className="flex justify-center items-center  font-bold">
                                        {" "}
                                        -{" "}
                                      </text>
                                    )}
                                  </td>
                                )}
                                {/* )} */}

                                <td className="w-[9%] cursor-paste px-2  py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  {candidate?.age}
                                </td>
                                <td className="w-[12%] px-2 cursor-paste py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  <Tooltip
                                    title={
                                      candidate?.email?.length > 20
                                        ? candidate?.email
                                        : ""
                                    }
                                  >
                                    {candidate?.email?.length > 20
                                      ? `${candidate?.email.substring(
                                          0,
                                          20
                                        )}...`
                                      : candidate?.email}
                                  </Tooltip>
                                </td>

                                <td
                                  className={`w-[5%] px-2  py-2 text-[15px] font-medium ${
                                    candidate?.profileurl
                                      ? "text-[#21D6AA]"
                                      : "text-gray-400"
                                  } `}
                                >
                                  <Tooltip title="ATS Profile">
                                    <div
                                      className={`flex justify-center gap-1 ${
                                        candidate?.profileurl
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed"
                                      }`}
                                      onClick={() =>
                                        candidate?.profileurl &&
                                        window.open(
                                          candidate?.profileurl,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <p
                                        style={{
                                          color: candidate?.profileurl
                                            ? "#21D6AA"
                                            : "#708ea4",
                                        }}
                                      >
                                        ATS
                                      </p>
                                      <img
                                        src={
                                          candidate?.profileurl
                                            ? "/images/profile-icon.svg"
                                            : "/images/profile-icon-disable.svg"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </Tooltip>
                                </td>

                                <td className="w-[5%] px-2 py-2 text-[15px] font-medium">
                                  <Tooltip title="External Link">
                                    <img
                                      className={` ${
                                        candidate?.externallink
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed"
                                      }`}
                                      src={
                                        candidate?.externallink
                                          ? "/images/external-link.svg"
                                          : "/images/Disabled External Link.svg"
                                      }
                                      alt=""
                                      onClick={() =>
                                        candidate?.externallink &&
                                        window.open(
                                          candidate?.externallink,
                                          "_blank"
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </td>

                                <td className="w-[5%]  py-2 text-[15px] font-medium text-[#21D6AA]">
                                  <Tooltip title="Download Resume">
                                    <img
                                      className=" cursor-pointer"
                                      src="/images/resumee.svg"
                                      onClick={() =>
                                        downloadResume(candidate?.resumeurl)
                                      }
                                      alt=""
                                    />
                                  </Tooltip>
                                </td>

                                <td className=" w-[5%] px-2 text-[#708EA4] text-sm font-normal">
                                  {formatDateTime(candidate?.createddate)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default ProcessedCandidateByJob;
